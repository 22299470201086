<template>
  <div class="identify">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom:20px;">
          识别商品
          <el-button @click="pushToPhonePage" size="mini">切换手机版</el-button>
        </div>
        <el-form :inline="true" id="el-form" ref="form" :model="form" class="demo-form-inline" size="mini">
          <el-form-item label="快递单号" prop="mailNo">
            <el-input ref="input" v-model="form.goodsCode" @keyup.native.enter="nextFocus($event)" clearable></el-input>
          </el-form-item>
          <el-form-item class="btn-right">
            <el-button @click="identifyOn" :disabled="isConfirming">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

  </div>
</template>

<script>
import {getGoodsByCode} from '@/services/product.js'
import Speech from 'speak-tts'
export default {
  name: 'identifyGoods',
  data() {
    return {
      userInfo: {},
      form: {
      },
      isConfirming: false, // 是否正在进行确认操作
    }
  },
  methods: {
    pushToPhonePage() {
      this.$router.push('/phone/identifyGoods');
    },
    initInputDOM() {
      //获取id为el-form下的所有input 框
      const inputDoms = document.querySelectorAll(
        "#el-form .el-input__inner"
      );
      //遍历这个input框给他们一个标识
      inputDoms.forEach((item, index) => {
        item.setAttribute("data-index", index);
      });
      this.inputDoms = inputDoms;
    },
    //回车事件
    nextFocus(event) {
      const index = event.target.getAttribute("data-index");
      console.log('index',index)
      this.inputDoms[0].focus()
      this.onSubmit()
    },
    // 上传
    async onSubmit() {
      await this.identifyOn()
    },

    async identifyOn() {
      try {
        if (this.isConfirming) {
          // 如果已经在进行确认操作，不执行重复操作
          return;
        }
        // 禁用确认按钮
        this.isConfirming = true;
        if (!this.form ||!this.form.goodsCode) {
          this.speak('商品编码必须输入，请重新扫描')
          return this.$message.error('商品编码必须输入，请重新扫描')
        }
        const params = this.form.goodsCode.trim()
        const {data} = await getGoodsByCode(params)
        if (data.code === 200) {
          let goodsName = data.data.goodsName
          goodsName = goodsName.replace("水性清漆","水性")
          goodsName = goodsName.replace("水性底漆","水性")
          goodsName = goodsName.replace("水性高耐磨清漆","")
          goodsName = goodsName.replace("环保水性漆","水性漆")
          goodsName = goodsName.replace("水性硅藻乳胶漆","内墙漆")
          goodsName = goodsName.replace("防水涂料-30","防水涂料三零型")

          let specsName = data.data.specsName
          specsName = specsName.replace(".", "点")
          specsName = specsName.replace("L", "升")
          this.speak(goodsName+data.data.colourName+specsName)
          this.$message.success(goodsName+data.data.colourName+specsName)
        } else {
          this.speak(data.message)
          this.$message.error(data.message)
        }
        this.form.goodsCode=''
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      } catch (e) {
        this.speak(e.message)
      } finally {
        this.isConfirming = false;
      }
    },

    speechInit(){
      this.speech = new Speech();
      this.speech.setLanguage('zh-CN');
      this.speech.init().then(()=>{
        console.log('语音播报初始化完成...')
      })
    },

    //语音播报
    speak(msg){
      this.speech.speak({text:msg}).then(()=>{
        console.log("播报完成...")
      })
    },
  },
  mounted() {
    this.speechInit();
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  },
  created() {
    this.$nextTick(() => {
      this.initInputDOM();
    })
  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>
.statistic{
  margin: 10px;
}
</style>
